import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Button from "../components/Button"

export const query = graphql`
{
  bonfire: file(base: {eq: "bonfire.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        transformOptions: {
          cropFocus: NORTH,
          fit: COVER
        },
      )
    }
  },
  girasoli: file(base: {eq: "girasoli.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        transformOptions: {
          cropFocus: NORTH,
          fit: COVER
        },
      )
    }
  },
  martinPescatore: file(base: {eq: "testa-martin-pescatore.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        transformOptions: {
          cropFocus: NORTH,
          fit: COVER
        },
      )
    }
  },
}
`;

const IndexPage = ({ data }) => {
  const { bonfire, girasoli, martinPescatore } = data;

  return (
    <Layout>
      <Seo title="Home" />
      <Hero title="Benvenuti" />
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <em>"Chi conosce i fantasmi dice che essi anelano ad essere liberati dalla loro vita di fantasmi e condotti a riposare come antenati. Come antenati continuano a vivere nella generazione presente, mentre come fantasmi sono costretti a ossessionarla con la loro vita di ombre. [...] Alla luce del giorno dell'analisi i fantasmi dell'inconscio ritrovano riposo e sono ricondotti alla pace degli antenati e il loro potere viene trasformato in una rinnovata intensità della vita nel presente."</em>
          <p className="mt-4 text-center">Hans Loewald</p>
        </article>
      </section>
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 relative text-right">
          <GatsbyImage
            image={bonfire.childImageSharp.gatsbyImageData}
            alt="bonfire"
            className="h-64"
          />
          <div className="absolute top-8 right-8 text-white">
            <h1>Bonfire</h1>
          </div>
          <div className="absolute bottom-8 right-8 text-white">
            <span className="text-2xl">Una pausa per i viandanti</span>
          </div>
        </article>
      </section>
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <p>Se siete arrivati fin qua, forse è perchè sentite il bisogno di <b>fermarvi</b> e <b>concedere del tempo solo per voi</b>. Avete bisogno di prendere delle decisioni importanti, girare pagina, chiudere dei conti in sospeso, affrontare di petto un problema non più rimandabile. Ma da soli, come vi siete accorti, non è facile.</p>
          <p className="mt-2">Quando un cristallo cade, questo non si frattura in modo casuale ma seguendo specifiche linee di faglia i cui limiti e direzioni - anche se invisibili fino a quel momento - sono già predeterminati. Allo stesso modo, la nostra struttura mentale acquisisce progressivamente una forma grazie sia a fattori costitutivi ereditari che per l'influsso della "temperatura" e delle "pressioni" dell'ambiente circostante, finendo per fratturarsi in modi specifici ed originali.</p>
          <p className="mt-2">Ogni cristallo è unico e, in questo senso, <b>non esiste una soluzione univoca e preconfezionata</b> per quelle fratture che possiamo chiamare <b>ansia</b>, <b>attacchi di panico</b>, <b>depressione</b>, <b>ritiro sociale</b>, <b>disfunzione sessuale</b>.</p>
          <p className="mt-2"><b>Non si può stabilire a priori quanto tempo ci vorrà</b> per colmare queste fratture, a meno che non si vogliano ricercare delle pseudo-soluzioni.</p>
          <p className="mt-2">Ma soprattutto, <b>non si può sperare in un intervento "ortopedico"</b> come si farebbe con un osso: una mente fratturata non può essere ricomposta, non può tornare "come prima", anche perchè è proprio il "prima" ad aver determinato la frattura. Possiamo però trasformare la sofferenza della frattura in una nuova opportunità, capire il passato per liberarcene rimanendo noi stessi e allo stesso tempo diventare qualcosa di profondamente diverso.</p>
          <p className="mt-2">Come nell'arte del <b>kintsugi</b> giapponese, dove un coccio rotto viene ricomposto saldando le fratture con metalli preziosi.</p>
        </article>
      </section>
      <section className="grid grid-cols-6 pt-6">
        <article className="col-span-6 relative text-right">
          <GatsbyImage
            image={girasoli.childImageSharp.gatsbyImageData}
            alt="girasoli"
            className="h-64"
          />
          <div className="absolute top-8 right-8">
            <h1>Come posso aiutarti</h1>
          </div>
          <div className="absolute bottom-8 right-8">
            <Button text="Prestazioni" to="/prestazioni" />
          </div>
        </article>
      </section>
      <section className="grid grid-cols-6 pb-6">
        <article className="col-span-6 relative text-left">
          <GatsbyImage
            image={martinPescatore.childImageSharp.gatsbyImageData}
            alt="martin-pescatore"
            className="h-64"
          />
          <div className="absolute top-8 left-8">
            <h1>Chi sono</h1>
          </div>
          <div className="absolute bottom-8 left-8">
            <Button text="Info" to="/chi-sono" />
          </div>
        </article>
      </section>
      <section className="grid grid-cols-6 py-6">
        <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <span className="text-2xl">Perchè un Martin Pescatore come logo?</span>
          <p className="mt-2">Mi piace pensare al <b>terapeuta</b> come ad un Martin Pescatore.</p>
          <p className="mt-2">Il suo nome - specie nella sua versione inglese ("Kingfisher") - richiama alla memoria la figura del Re Pescatore dei cicli arturiani: i miti, le leggende e le vecchie storie del passato continuano a dirci molto di noi, del nostro presente, e tornare ad ascoltare le loro suggestioni è un primo passo per riprenderci in mano la nostra vita. Essa stessa è una <b>storia</b>, una <b>narrazione</b>, di cui <b>noi siamo i protagonisti</b>: spesso, però, molte delle sue pagine sono state strappate via, vi è colato sopra dell'inchiostro o sembrano essere state scritte da altri tanto che il senso complessivo sembra essersi smarrito. Compito del terapeuta, insieme al paziente, è quello di ricostruire insieme una trama densa di <b>significati</b>.</p>
          <p className="mt-2">Il Martin Pescatore, poi, vive sempre vicino all'acqua. L'acqua, l'<b>inconscio</b>, è un elemento essenziale per un lavoro profondo su sè stessi: la nostra consapevolezza gioca spesso infatti un ruolo marginale, mentre per la gran parte del tempo noi siamo agiti e siamo pensati da un Altro con il quale dobbiamo tornare a dialogare e camminare insieme.</p>
          <p className="mt-2">Il Martin Pescatore non possiede grandi dimensioni e forza spropositata ma in compenso si caratterizza per la sua resistenza e perseveranza, rimanendo posato anche per molte ore su un medesimo ramo, con lo sguardo rivolto all'acqua, in attesa della preda. Una volta individuata, dopo un'attenta valutazione delle distanze spicca il volo, si tuffa per catturarla e ritorna poi al suo punto di osservazione per consumarla con calma. Si tratta di un uccello molto silenzioso, ma non muto. Allo stesso modo, il terapeuta <b>non si accanisce sul sintomo</b> estirpandolo a forza, proponendo pratiche miracolose o soluzioni immediate: il suo è un <b>lavoro lento</b>, fatto soprattutto di <b>attese ed ascolto attento</b>, finalizzato a trarre dall'acqua del materiale nascosto per ridare <b>un senso ed una dignità</b> ai sintomi, riallacciare la trama della vita e quindi favorire una sua ripresa.</p>
        </article>
      </section>
    </Layout>
  )
}

export default IndexPage
